import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlockContent from "../components/block-content";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import TextContainer from "../components/text-container";

export const query = graphql`
query PostQuery($id: String = "") {
  post: sanityPost(id: {eq: $id}) {
    slug {
      current
    }
    _rawBody(resolveReferences: {maxDepth: 10})
    title
    seo {
      title
      description
      image {
        asset {
          url
        }
      }
    }
    author {
      name
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData(width: 60, height: 60)
        }
      }
    }
    publishedAt
    mainImage {
      asset {
        gatsbyImageData(width: 1920, height: 600, fit: CROP)
        url
      }
    }
  }
}

`;

const Post = props => {
  const post = props.data.post;
  return (
    <Layout title={post.seo?.title} description={post.seo?.description} image={post.seo.image ? post.seo.image.asset.url : post.mainImage.asset.url} location={"/blog/" + post.slug.current}>
      <div className="max-w-7xl px-4 mb-12 mx-auto">
        <GatsbyImage className="rounded-lg" image={getImage(post.mainImage.asset)} alt={post.title} />
      </div>
      <TextContainer >
        <h1>{post.title}</h1>
        <div className="mt-6 flex items-center mb-6">
          <div className="flex-shrink-0">
            <a href={post.author.href}>
              <span className="sr-only">{post.author.name}</span>
              <GatsbyImage className="w-10 h-10 rounded-full" image={getImage(post.author.image.asset)} alt={post.author.name} />
            </a>
          </div>
          <div className="ml-3">
            <span className="text-sm font-medium text-gray-900">
              <a href={"/attorneys/" + post.author.slug.current} className="hover:underline unset">
                {post.author.name}
              </a>
            </span>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time dateTime={post.publishedAt}>{new Date(post.publishedAt).toLocaleDateString('en-US')}</time>
              {/* <span aria-hidden="true">&middot;</span>
                      <span>{node.readingTime} read</span> */}
            </div>
          </div>
        </div>
        <div className="post pb-20">
          <BlockContent blocks={post._rawBody || []} />
        </div>
      </TextContainer>
    </Layout>
  )
}

export default Post